<template>
  <div>
    <div class="d-flex align-items-center flex-column">
      <div class="col-md-12">
        <div
          class="card"
          style="
            border-left: 8px solid #004488;
            border-right: 8px solid #004488;
          "
        >
          <div class="row d-flex align-items-center flex-column">
            <div class="col-md-11 p-1">
              <div class="card-title mt-1">Novo Post:</div>
              <ckeditor v-model="text" :editor="editor" ref="editorInstance" />
              <div
                class="row d-flex align-items-center justify-content-end mt-1 px-1"
              >
                <label for="images" class="btn btn-add btn-crud p-0 mr-1">
                  <i
                    class="fas fa-camera d-flex align-items-center justify-content-center"
                    style="height: 100%"
                  ></i>
                  <input
                    type="file"
                    id="images"
                    class="d-none"
                    @change="uploadImage"
                    ref="file"
                    multiple
                  />
                </label>
                <button class="btn btn-primary btn-sm" @click="newPost()">
                  Postar
                </button>
              </div>
              <hr v-if="previewImage" />
              <div class="card-title" v-if="previewImage">Imagem do Post:</div>
              <div class="user-img">
                <img v-if="previewImage" :src="previewImage" class="photo" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12" v-if="approves.length && $store.state.user.mod">
        <button
          class="w-100 btn btn-primary"
          type="button"
          data-toggle="collapse"
          data-target="#moderar"
          aria-expanded="false"
          aria-controls="moderar"
        >
          Posts aguardando aprovação ({{ approves.length }})
        </button>
        <div
          class="collapse"
          id="moderar"
          style="border-bottom: 1px solid black"
        >
          <div
            v-for="(approve, index) in approves"
            :key="index"
            class="card p-1"
            :style="
              'border-bottom: 1px solid ' +
              approve.primary_color +
              '; border-top: 1px solid ' +
              approve.primary_color +
              '; border-right: ' +
              (approve.id % 2 ? 1 : 8) +
              'px solid ' +
              approve.primary_color +
              '; border-left: ' +
              (approve.id % 2 ? 8 : 1) +
              'px solid ' +
              approve.primary_color
            "
          >
            <div
              class="row p-0"
              :class="approve.id % 2 ? '' : 'flex-row-reverse'"
            >
              <div
                :style="
                  'border-bottom: ' +
                  approve.secondary_color +
                  ' 1px solid; padding-bottom: 8px;'
                "
                class="col-12 d-flex align-items-center justify-content-start"
              >
                <b-avatar
                  size="50"
                  variant="light-primary"
                  :src="approve.user.photo != false ? approve.user.photo : '.'"
                  class="badge-minimal"
                />
                <b class="px-1" style="font-size: 3vh; line-height: 2.2vh"
                  >{{ approve.user.name }}<br /><span style="font-size: 2vh">{{
                    approve.created_at_f
                  }}</span></b
                >
              </div>
            </div>
            <div class="d-flex p-1 flex-column" v-html="approve.text"></div>
            <div class="d-flex align-items-center justify-content-start">
              <div
                v-for="(image, imageIndex) in approve.images"
                :key="imageIndex"
                class="ml-1 mb-1"
                :style="
                  'background-repeat: no-repeat; background-size: cover; background-position: center; cursor: pointer; border-radius: 8px; border: solid ' +
                  approve.primary_color +
                  ' 1px; background-image: url(' +
                  image.photo +
                  '); width: 35vh; height: 35vh;'
                "
              ></div>
            </div>
            <div class="d-flex justify-content-center align-itens-center">
              <div class="col-md-6 text-center">
                <button
                  class="btn btn-outline-danger"
                  @click="setApprove(approve.id, 2)"
                >
                  <i class="fas fa-ban"></i> Rejeitar
                </button>
              </div>
              <div class="col-md-6 text-center">
                <button
                  class="btn btn-outline-success"
                  @click="setApprove(approve.id, 1)"
                >
                  <i class="fas fa-check"></i> Aprovar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-1 col-md-12" v-for="(post, index) in posts" :key="index">
        <div
          class="card p-1"
          :style="
            posts.length % 2
              ? post.id % 2
                ? 'border-left: 8px solid ' + post.primary_color + ';'
                : 'border-right: 8px solid ' + post.primary_color + ';'
              : post.id % 2
              ? 'border-left: 8px solid ' + post.primary_color + ';'
              : 'border-right: 8px solid ' + post.primary_color + ';'
          "
        >
          <div
            class="row p-0"
            :class="
              posts.length % 2
                ? post.id % 2
                  ? ''
                  : 'flex-row-reverse'
                : post.id % 2
                ? ''
                : 'flex-row-reverse'
            "
          >
            <div
              :style="
                'border-bottom: ' +
                post.secondary_color +
                ' 1px solid; padding-bottom: 8px;'
              "
              :class="
                posts.length % 2
                  ? post.id % 2
                    ? ''
                    : 'flex-row-reverse'
                  : post.id % 2
                  ? ''
                  : 'flex-row-reverse'
              "
              class="col-11 d-flex align-items-center justify-content-start"
            >
              <b-avatar
                size="50"
                variant="light-primary"
                :src="post.user.photo != false ? post.user.photo : '.'"
                class="badge-minimal"
              />
              <b class="px-1" style="font-size: 3vh; line-height: 2.2vh"
                >{{ post.user.name }}<br />
                <span style="font-size: 2vh">{{ post.created_at_f }}</span>
              </b>
            </div>
            <div
              class="col-1 d-flex"
              :class="
                posts.length % 2
                  ? !post.id % 2
                    ? 'flex-end'
                    : 'flex-start'
                  : !post.id % 2
                  ? 'flex-end'
                  : 'flex-start'
              "
            >
              <button
                class="text-end btn btn-crud btn-outline-danger"
                style="display: fixed; position: relative; left: 0"
                title="Excluir post"
                @click="deletePost(post.id)"
                v-show="$store.state.user.mod"
              >
                <i class="fas fa-trash"></i>
              </button>
              <div
                class="modal fade"
                :id="'delete_' + post.id"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h1 class="modal-title fs-5" id="exampleModalLabel">
                        Tem certeza que deseja deletar o post?
                      </h1>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="deletePost(post.id)"
                      >
                        Save changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              posts.length % 2
                ? post.id % 2
                  ? 'justify-content-start align-items-start'
                  : 'justify-content-end align-items-end'
                : post.id % 2
                ? 'justify-content-start align-items-start'
                : 'justify-content-end align-items-end'
            "
            class="d-flex p-1 flex-column"
            v-html="post.text"
          ></div>
          <div
            :class="
              posts.length % 2
                ? post.id % 2
                  ? 'd-flex align-items-center justify-content-start'
                  : 'd-flex align-items-center justify-content-start flex-row-reverse'
                : post.id % 2
                ? 'd-flex align-items-center justify-content-start'
                : 'd-flex align-items-center justify-content-start flex-row-reverse'
            "
          >
            <a
              v-for="(image, imageIndex) in post.images"
              :key="imageIndex"
              :href="image.photo"
              target="_blank"
              class="ml-1 mt-1"
              :style="
                'background-repeat: no-repeat; background-size: cover; background-position: center; cursor: pointer; border-radius: 8px;' +
                'border: solid ' +
                post.primary_color +
                ' 1px; background-image: url(' +
                image.photo +
                '); width: 35vh; height: 35vh;'
              "
            ></a>
          </div>
          <div
            class="row mt-1 px-1"
            :style="'border-bottom: solid ' + post.secondary_color + ' 1px;'"
          >
            <button
              class="btn px-1"
              :style="post.liked ? 'color: #4488aa;' : ''"
              @click="react(post.id, index)"
            >
              <i
                class="fas fa-thumbs-up"
                data-toggle="tooltip"
                data-placement="top"
                title="Curtir"
              ></i>
            </button>
            <a
              class="like-link"
              style="margin-top: 9px"
              data-toggle="modal"
              data-target="#modalPosts"
              v-if="post.likes_count !== 0"
            >
              <b
                >{{ post.likes_count }} curtida{{
                  post.likes_count > 1 ? "s" : ""
                }}</b
              >
            </a>
            <button
              class="btn pr-1"
              @click="getComments(post.id, index, post.show_comments)"
            >
              <i
                :class="
                  'pr-1 fas fa-' + (post.show_comments ? 'ban' : 'comment')
                "
              ></i>
              <b>{{ post.show_comments ? "Cancelar" : "Comentar" }}</b>
            </button>
          </div>
          <br />
          <br />
          <div v-show="post.show_comments">
            <div>
              <div
                style="
                  position: relative;
                  display: flex;
                  align-items: center;
                  border: 1px solid #ced4da;
                  border-radius: 0.25rem;
                  overflow: hidden;
                "
              >
                <input
                  type="text"
                  class="form-control"
                  style="flex-grow: 1; border: none; border-radius: 0"
                  v-model="comment[post.id]"
                  placeholder="Escreva seu comentário..."
                  @keyup.enter="addComment(post.id, index)"
                />
                <div
                  style="
                    position: absolute;
                    right: 50px;
                    top: 0;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                  "
                >
                  <button
                    class="btn"
                    style="background: transparent; border: none"
                    @click="toggleEmojiPicker(post.id)"
                  >
                    <i class="fas fa-smile"></i>
                  </button>
                </div>

                <button
                  class="btn btn-primary"
                  style="
                    margin-left: -1px;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                  "
                  @click="addComment(post.id, index)"
                >
                  <i class="fas fa-arrow-right"></i>
                </button>
              </div>
              <picker
                style="position: absolute; right: 0; z-index: 999"
                v-if="emojiPickerVisible[post.id]"
                @select="(emoji) => addEmoji(emoji, post.id)"
              />
            </div>
            <br /><br />
            <div
              class="d-flex align-items-center justify-content-start flex-row flex-wrap"
              :class="
                posts.length % 2
                  ? post.id % 2
                    ? ''
                    : 'flex-row-reverse'
                  : post.id % 2
                  ? ''
                  : 'flex-row-reverse'
              "
              v-if="post.comments.length"
            >
              <div
                class="px-2 py-1 w-100"
                style="border-top: 1px solid #ccc"
                v-for="(comment, commentIndex) in post.comments"
                :key="commentIndex"
              >
                <b class="my-1">
                  <b-avatar
                    size="30"
                    variant="light-primary"
                    :src="post.user.photo != false ? post.user.photo : '.'"
                    class="badge-minimal"
                  />
                  {{ comment.user.name }} - {{ comment.created_at_f }}
                  <br />
                  <hr />

                  <span style="font-weight: 500">
                    {{ comment.text }}
                  </span>
                  <br />
                </b>
                <br />
                <button
                  class="btn pl-0"
                  @click="deleteComment(comment.id, post.id, index)"
                >
                  <i class="fas fa-trash"></i> <b>Excluir</b>
                </button>

                <button
                  class="btn px-1"
                  :style="comment.liked ? 'color: #4488aa;' : ''"
                  @click="
                    reactToComment(post.id, comment.id, index, commentIndex)
                  "
                >
                  <i
                    class="fas fa-thumbs-up"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Curtir"
                  ></i>
                </button>
                <a
                  class="like-link"
                  data-toggle="modal"
                  data-target="#modalComments"
                  v-if="comment.likes_count !== 0"
                >
                  <b
                    >{{ comment.likes_count }} curtida{{
                      comment.likes_count > 1 ? "s" : ""
                    }}</b
                  >
                </a>

                <!-- <button class="btn px-1">
                  <i class="fas fa-comment"></i> <b>Responder</b>
                </button> -->
              </div>
            </div>
            <div class="py-1 text-center" v-else>
              <b>Não há comentários</b>
            </div>
            <div
              class="modal fade"
              id="modalComments"
              tabindex="-1"
              role="dialog"
              aria-labelledby="modalCommentsTitle"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header text-center">
                    <h5
                      class="modal-title text-center"
                      id="exampleModalLongTitle"
                    >
                      Curtidas
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <div class="col-md-6">
                        <div v-if="selectedCommentLikes.length > 0">
                          <ul class="list-unstyled mt-1">
                            <li
                              v-for="like in selectedCommentLikes"
                              :key="like.id"
                            >
                              <b-avatar
                                size="40"
                                variant="light-primary"
                                :src="like.user.photo ? like.user.photo : ''"
                                class="mr-2"
                              ></b-avatar>
                              <span class="font-weight-bold">{{
                                like.user.name
                              }}</span>
                              <hr style="width: 210%" />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Fechar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal fade"
            id="modalPosts"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modalPostsTitle"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header text-center">
                  <h5
                    class="modal-title text-center"
                    id="exampleModalLongTitle"
                  >
                    Curtidas
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div v-if="selectedPostLikes.length > 0">
                        <ul class="list-unstyled mt-1">
                          <li v-for="like in selectedPostLikes" :key="like.id">
                            <b-avatar
                              size="40"
                              variant="light-primary"
                              :src="like.user.photo ? like.user.photo : ''"
                              class="mr-2"
                            ></b-avatar>
                            <span class="font-weight-bold">{{
                              like.user.name
                            }}</span>
                            <hr style="width: 210%" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Fechar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCardText, BLink } from "bootstrap-vue";
import { BCard, BCardBody, BAvatar } from "bootstrap-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Picker } from "emoji-mart-vue";

export default {
  data() {
    return {
      selectedCommentLikes: [],
      selectedPostLikes: [],
      text: "",
      posts: [],
      approves: [],
      comment: [],
      emojiPickerVisible: {},
      editor: ClassicEditor,
      editorConfig: {
        width: "200px",
        height: "200px",
      },
      previewImage: null,
      post_id: null,
    };
  },
  components: {
    BCard,
    BCardText,
    BLink,
    BCard,
    BCardBody,
    BAvatar,
    Picker,
  },
  methods: {
    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
      };
    },

    getPosts: function () {
      const self = this;
      const api =
        self.$store.state.api +
        "posts?with[]=user&with[]=likes&with[]=images&approved=1&orderByDesc=created_at";

      self.$loading(true);

      axios
        .get(api)
        .then((response) => {
          self.posts = response.data.data;

          self.posts.forEach((post) => {
            if (!post.hasOwnProperty("liked")) {
              post.liked = false;
            }
            if (!post.hasOwnProperty("likes_count")) {
              post.likes_count = post.likes.length;
            }

            post.likes.forEach((like) => {
              if (like.user_id === self.$store.state.user.id) {
                post.liked = true;
              }
            });
            self.selectedPostLikes = []; // Limpar a lista de likes antes de preencher
            if (response.data.data.length > 0) {
              self.selectedPostLikes = response.data.data[0].likes; // Preencher com os likes do primeiro comentário
            }
            self.$loading(false);
          });
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },

    //lógica do envio de comentários

    sendComment(postId, index) {
      if (this.newComment.trim() !== "") {
        this.addComment(postId, index);
        this.newComment = "";
      }
    },
    addComment(postId, index) {},

    getApproves: function () {
      const self = this;
      const api =
        self.$store.state.api +
        "posts?with[]=user&with[]=images&approved=0&orderByAsc=created_at";

      self.$loading(true);

      axios
        .get(api)
        .then((response) => {
          self.approves = response.data.data;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      let photo = document.getElementById("images"); // Pega todas as fotos

      for (let i = 0; i < photo.files.length; i++) {
        fd.append("photo_" + i, photo.files[i] ? photo.files[i] : ""); // Processa cada foto pra um append novo
      }

      fd.append("photo_length", photo.files.length); // Quantidade de fotos que estão sendo carregadas
      fd.append("user_id", self.$store.state.user.id);
      fd.append("text", self.text);
      fd.append("approved", 0);

      fd.append("_method", "POST");

      return fd;
    },
    newPost: function () {
      const self = this;
      const api = self.$store.state.api + "posts";

      let fd = self.makeFormData();

      self.$loading(true);

      axios
        .post(api, fd)
        .then(() => {
          self.text = "";
          self.previewImage = "";

          if (self.$store.state.user.mod) {
            self.getApproves();
          }

          self.getPosts();

          self.$message(
            null,
            "Post feito, por favor aguarde a aprovação de um moderador",
            "success"
          );
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    getComments: function (id, index, active) {
      const self = this;
      console.log("getComment");

      self.$loading(true);

      if (!active) {
        const api =
          self.$store.state.api +
          "comments?with[]=user&with[]=comment_likes&orderByDesc=created_at&post_id=" +
          id;

        self.comment[id] = "";

        axios
          .get(api)
          .then((response) => {
            self.posts[index].comments = response.data.data;

            self.posts[index].comments.forEach((comment) => {
              if (
                !comment.hasOwnProperty("likes_count") ||
                typeof comment.likes_count !== "number"
              ) {
                comment.likes_count = comment.comment_likes.length;
              }

              comment.liked = false;

              comment.comment_likes.forEach((like) => {
                if (like.user_id === self.$store.state.user.id) {
                  comment.liked = true;
                }
              });
              self.$loading(false);
            });

            self.posts[index].show_comments = true;
            self.selectedCommentLikes = []; // Limpar a lista de likes antes de preencher
            if (response.data.data.length > 0) {
              self.selectedCommentLikes = response.data.data[0].comment_likes; // Preencher com os likes do primeiro comentário
            }
            console.log("getComment in");
            self.$loading(false);
          })
          .catch((error) => {
            self.$loading(false);
            self.$message(null, error.response.data, "error");
          });
      } else {
        self.posts[index].show_comments = false;
      }
    },
    selectCommentLikes: function (comment) {
      this.selectedCommentLikes = comment.comment_likes;
    },
    selectPostLikes: function (post) {
      this.selectedPostLikes = post.liked;
    },

    addComment: function (id, index) {
      const self = this;
      const api = self.$store.state.api + "comments";

      self.$loading(true);

      self.$http
        .post(api, {
          text: self.comment[id],
          post_id: id,
          user_id: self.$store.state.user.id,
        })
        .then(() => {
          self.posts[index].show_comments = true;
          self.posts;
          self.getComments(id, index);
          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });

      self.comment[id] = "";
      self.comment.push();
    },

    addEmoji(emoji, postId) {
      this.comment[postId] = this.comment[postId] + emoji.native;
      this.comment.push();
    },

    react: function (postId, index) {
      const self = this;
      let api = "";

      self.posts[index].liked = !self.posts[index].liked;

      if (self.posts[index].liked) {
        api = `${self.$store.state.api}likes/${postId}/like`;
      } else {
        api = `${self.$store.state.api}delete-like`;
      }

      axios
        .post(api, { post_id: postId, user_id: self.$store.state.user.id })
        .then((response) => {
          self.posts[index].likes_count = response.data.likes_count;
          self.getPosts();
        })
        .catch((error) => {
          console.error("API error:", error);
          self.$message(null, error.response.data, "error");
        });
    },

    reactToComment: function (postId, commentId, index, commentIndex) {
      const self = this;
      let api = "";

      self.posts[index].comments[commentIndex].liked =
        !self.posts[index].comments[commentIndex].liked;

      if (self.posts[index].comments[commentIndex].liked) {
        self.posts[index].comments[commentIndex].likes_count++;
      } else {
        self.posts[index].comments[commentIndex].likes_count--;
      }

      if (self.posts[index].comments[commentIndex].liked) {
        api = `${self.$store.state.api}comment_likes/${commentId}/like`;
      } else {
        api = self.$store.state.api + "delete-comment-like";
      }
      self.$http
        .post(api, {
          comment_id: commentId,
          user_id: self.$store.state.user.id,
        })
        .then(() => {
          self.$forceUpdate();
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    setApprove: function (id, status) {
      const self = this;
      const api = self.$store.state.api + "approve/";

      self.$http
        .post(api, { id: id, approved: status })
        .then(() => {
          self.getApproves();
          self.getPosts();

          if (status === 1) {
            self.$message(null, "Post Aprovado com Sucesso!", "success");
          } else {
            self.$message(null, "Post Rejeitado com Sucesso!", "error");
          }
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    toggleEmojiPicker(postId) {
      // Inverte a visibilidade para o post específico
      this.$set(
        this.emojiPickerVisible,
        postId,
        !this.emojiPickerVisible[postId]
      );
    },

    // deletePost: function(id)
    // {
    //   const self = this;
    //   const api = self.$store.state.api + "delete-post";

    //   self.$http
    //     .post(api, {id: id})
    //     .then(() => {
    //       self.getPosts();
    //     })
    //     .catch((error) => {
    //       self.$message(null, error.response.data, "error");
    //     });
    // },
    deletePost(id) {
      const self = this;

      swal({
        title: "Deseja deletar o registro?",
        text: "Essa operação não pode ser desfeita",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, deletar!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("Cancelado", "O registro não foi excluido!", "info");
            break;

          case "confirm":
            let api = self.$store.state.api + "delete-post";
            axios
              .post(api, { id: id })
              .then((response) => {
                swal("Sucesso", "Registro excluído com sucesso!", "success");
                self.getPosts();
              })
              .catch((error) => {
                swal(
                  "Erro",
                  "Este registro não pode ser deletado, pois já está sendo utilizado no sistema!",
                  "error"
                );
              });
            break;
        }
      });
    },
    deleteComment(commentId, postId, postIndex) {
      const self = this;

      swal({
        title: "Deseja deletar o comentário?",
        text: "Essa operação não pode ser desfeita",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, deletar!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
          },
        },
      }).then((confirmed) => {
        if (confirmed === "confirm") {
          swal({
            title: "Aguarde...",
            text: "Excluindo comentário...",
            icon: "info",
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false,
          });

          const api = self.$store.state.api + "comments/" + commentId;

          self.$http
            .delete(api)
            .then(() => {
              console.log(postId);
              console.log(postIndex);
              self.getComments(postId, postIndex, false);

              const commentIndex = self.posts[postIndex].comments.findIndex(
                (comment) => comment.id === commentId
              );
              if (commentIndex !== -1) {
                self.posts[postIndex].comments.splice(commentIndex, 1);
              }
              swal.close();
              swal("Sucesso", "Comentário apagado com sucesso", "success");
            })
            .catch((error) => {
              swal.close();
              swal("Erro", error.response.data, "error");
            });
        }
      });
    },
  },
  mounted() {
    const self = this;

    self.getPosts();
    self.getApproves();
  },
  created() {},
};
</script>

<style scoped>
.custom-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 255, 128, 0);
  backdrop-filter: blur(3px);
  opacity: 0.98;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.custom-loading-spinner {
  width: 100px;
  height: 100px;
  z-index: 10000;
}

.ck-editor__editable {
  min-height: 300px;
  color: black;
}

.ck-editor__editable h1,
.ck-editor__editable h2,
.ck-editor__editable h3,
.ck-editor__editable h4,
.ck-editor__editable h5,
.ck-editor__editable h6,
.ck-editor__editable .h1,
.ck-editor__editable .h2,
.ck-editor__editable .h3,
.ck-editor__editable .h4,
.ck-editor__editable .h5,
.ck-editor__editable .h6 {
  font-family: inherit !important;
  font-weight: 500 !important;
  line-height: 1.2 !important;
  color: black !important;
}

.like-link:hover b {
  color: #4488aa; /* Define a cor azul ao passar o mouse */
}

.like-link:hover b {
  color: #4488aa;
}
</style>
